import React, { useEffect, useState } from "react";
import {
    Grid,
    Table, TableHead, TableBody, TableCell, TableRow,
    makeStyles, Paper
} from "@material-ui/core";
import Layout from "../components/layout";
import { useQueryParam, Reservacion } from "use-query-params";
import axios from "axios"
import Typography from "@material-ui/core/Typography";
import { useIntl } from "gatsby-plugin-intl"
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const useStyles = makeStyles(() => ({
    codigoRes: {
        padding: "1rem",
        backgroundColor: "rgb(211,47,47)",
        color: "white"
    },
    header: {
        paddingTop: "0.5rem"
    }
}));



const ViewReservacion = ({ location }) => {
    const intl = useIntl();
    const [reservacion] = useQueryParam("reservacion", Reservacion)
    const [data, setData] = useState([])
    const [detalles, setDetalles] = useState([]);
    const [pasajeros, setPasajeros] = useState([])
    const [desglosePagos, setDesglosePagos] = useState([])
    const [comprobantes, setComprobantes] = useState([])
    const classes = useStyles();
    const [error, setError] = useState(null)



    useEffect(() => {
        async function getReservation() {
            axios.get(process.env.SERVER_URL + 'endpoint.php?action=unaReservacion&reservacion=' + reservacion)
                .then(function (response) {
                    const info = response.data
                    setData(info);
                    setDetalles(info.detalles);
                    setPasajeros(info.pasajeros)
                    setDesglosePagos(info.desglosePagos)

                    typeof info.comprobantes != "undefined" ? setComprobantes(info.comprobantes) : setComprobantes([])

                })
                .catch(function (error) {
                    console.log(error);
                    setError("Hubo un error al conseguir la información de la reserva" + error)

                })
        }
        getReservation()
    }, [reservacion]);

    return (
        <Layout>
            <Grid container>
                <Grid item md={10} xs={12}  >
                    <Typography variant="h5" component="h3" className={classes.header} gutterBottom>
                        {(data.tipo === 'escuela') ? intl.formatMessage({ id: "res.tituloEscuelas" }) : null}
                        {(data.camping == null || data.camping === false) ? intl.formatMessage({ id: "res.titulo" }) : intl.formatMessage({ id: "resCamping" })} {data.sector}.
                    </Typography>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Paper elevation={3} className={classes.codigoRes}>
                        <Typography variant="h6" >
                            {data.reservacion}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                        {intl.formatMessage({ id: "res.det" })}
                    </Typography>
                </Grid>
                {(error == null) ? null : (
                    <Grid item container xs={12}>
                        <Grid item container alignItems="center" style={{ backgroundColor: "rgb(253, 236, 234)", padding: "1rem" }}>
                            <Grid item xs={1}>
                                <ReportProblemIcon style={{ color: "#ad1d1d" }} />
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant={"body2"} style={{ color: "#ad1d1d" }} >
                                    {error}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <ul>
                        {detalles.map((field, index) => {
                            return (
                                <li key={index}>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{intl.formatMessage({ id: field.label }) + ": "}</strong>
                                        {field.value}
                                    </Typography>
                                </li>
                            )
                        })}
                        {(data.camping == true) ? (
                            <li key={"fechaSalida"}>
                                <Typography variant="body2" gutterBottom>
                                    <strong>{intl.formatMessage({ id: "fechaSalida" }) + ": "}</strong>
                                    {data.fechaSalida}
                                </Typography>
                            </li>
                        ) : null}
                    </ul>
                </Grid>

                <Grid item xs={12}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{intl.formatMessage({ id: "cantidad" })}</TableCell>
                                <TableCell>{intl.formatMessage({ id: "nombre" })}</TableCell>
                                <TableCell>{intl.formatMessage({ id: "pais" })}</TableCell>
                                <TableCell>{intl.formatMessage({ id: "provincia" })}</TableCell>
                                <TableCell>{intl.formatMessage({ id: "tarifa" })}</TableCell>
                                <TableCell>{intl.formatMessage({ id: "costo" })} {intl.formatMessage({ id: "pp" })}</TableCell>
                                <TableCell>{intl.formatMessage({ id: "costo" })}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {pasajeros.map((row, index) => {
                                const qty = (isNaN(row.cantidad)) ? 1 : row.cantidad;
                                return (

                                    ((!isNaN(row.cantidad) && row.cantidad > 0) || isNaN(row.cantidad)) && (
                                        <TableRow key={index} scope="row">
                                            <TableCell>{qty}</TableCell>
                                            <TableCell >
                                                {row.nombrePax}
                                            </TableCell>

                                            <TableCell>{row.nacional}</TableCell>
                                            <TableCell>{row.provincia}</TableCell>
                                            <TableCell>{row.tarifa}</TableCell>
                                            <TableCell>{row.moneda + row.costo}</TableCell>
                                            <TableCell>{row.moneda}{qty * row.costo}</TableCell>
                                        </TableRow>
                                    )
                                )
                            })

                            }
                            {(data.camping != true) ? null : (<TableRow>
                                <TableCell>{data.nochesCamping}</TableCell>
                                <TableCell>

                                    {intl.formatMessage({ id: "nochesCamp" })}

                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>$4</TableCell>
                                <TableCell>${data.paxCamping}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <Grid item container>
                {desglosePagos.map((pago, index) => {
                    return (
                        <Grid key={index} item xs={12} container justifyContent={"flex-end"}>
                            <Grid item xs={8}>
                                <Typography variant={"body2"}>{pago.label}</Typography>
                            </Grid>
                            <Grid item xs={1} >
                                <Typography variant={"body2"}>
                                    <span style={{ textAlign: "right" }}><strong>  {pago.value}</strong></span>
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            {comprobantes.length > 0 && comprobantes.map((adjunto, index) => {
                return (
                    <Grid item container key={index}>
                        <Grid item xs={8}>
                            <Typography variant={"body2"}>{intl.formatMessage({ id: "res.comp" })}</Typography>
                        </Grid>
                        <Grid key={index} item xs={12} container>
                            <Grid item>
                                {adjunto}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Layout>
    )

}
export default ViewReservacion;

